$bg: #fff;
$text: #382b22;
$light-purple: #D2C6E8;
$purple: #ffe9f9;
$dark-purple: #f9c4f7;
$purple-border: #b185a2;
$purple-shadow: #ffe2fd;

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  width: 100%;
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  &.btn {
    font-weight: 600;
    color: $text;
    text-transform: uppercase;
    padding: 1.25em 2em;
    background: $light-purple;
    border: 2px solid $purple-border;
    border-radius: 0.75em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark-purple;
      border-radius: inherit;
      box-shadow: 0 0 0 2px $purple-border, 0 0.625em 0 0 $purple-shadow;
      transform: translate3d(0, 0.75em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: $purple;
      transform: translate(0, 0.25em);
      &::before {
        box-shadow: 0 0 0 2px $purple-border, 0 0.5em 0 0 $purple-shadow;
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      background: $purple;
      transform: translate(0em, 0.75em);
      &::before {
        box-shadow: 0 0 0 2px $purple-border, 0 0 $purple-shadow;
        transform: translate3d(0, 0, -1em);
      }
    }
    &.grey-btn {
      background: #D7D7D7;
      border: 2px solid #D7D7D7;
      color: #382B22;
      &::before {
        background: #808080;
        box-shadow: 0 0 0 2px #6c6b6b, 0 0.625em 0 0 #343434;
      }
      &:hover {
        background: #D7D7D7;
        &::before {
          box-shadow: 0 0 0 2px #D7D7D7, 0 0.5em 0 0 #D7D7D7;
        }
      }
      &:active {
        background: #D7D7D7;
        &::before {
          box-shadow: 0 0 0 2px #D7D7D7, 0 0 #D7D7D7;
        }
      }
    }
  }
}
  