.red {
  background-color: red;
}
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;

}
a {
  text-decoration: none;
  color: inherit;
  font-family: 'Poppins', sans-serif;
}
.link {
  color: #0033ce;
  text-decoration: underline;
  font-size: 0.8em;
}
h1 {
  font-size: 3.5em;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
}
h2 {
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
}
h3 {
  font-size: 1.1em;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
p {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
textarea:focus, input:focus{
  outline: none;
}
.fullHR {
  width: 100% !important;
  opacity: 0.15;
}
.form-group {
  display: flex;
  flex-direction: column;
}
input, select {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 12px 16px;
  gap: 4px;
  width: 100%;
  height: 58px;
  background: #FFFFFF;
  border: 1.5px solid #D7D7D7;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 16px;
}
textarea {
  width: 100%;
  height: 150px;
  background: #FFFFFF;
  border: 1.5px solid #D7D7D7;
  border-radius: 8px;
  padding: 16px;
}
label {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 1em;
  text-align: left;
  margin-bottom: 16px;
}
::placeholder, .option-grey {
  color: #8E9399;
  opacity: 0.5;
}
select:invalid { color: #8E9399;
  opacity: 0.5; }
//GLOBAL
@import './styles/variables.scss';
@import './styles/utilities.scss';
@import './styles/mixins.scss';
@import './styles/_grid.scss';
// @import './styles/normalize.scss';

// COMPONENTS
@import './styles/buttons.scss';

// PAGES
@import './styles/page.scss';

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}