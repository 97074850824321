* {
    scroll-behavior: smooth;
  }
  li, ul {
    list-style-type: none;
  }
    .overflow-hidden {
    overflow: hidden !important;
  }
  .hidden {
    display: none !important;
  }
  .center {
    text-align: center !important;
  }
  .push-bottom-small {
    margin-bottom: $space-small !important;
  }
  .push-bottom-smedium {
    margin-bottom: $space-smedium !important;
  }
  .push-bottom-medium {
    margin-bottom: $space-medium !important;
  }
  .push-bottom-large {
    margin-bottom: $space-large !important;
  }
  .push-bottom-xlarge {
    margin-bottom: $space-xlarge !important;
  }
  .push-bottom-xxlarge {
    margin-bottom: $space-xxlarge !important;
  }
  .push-right-small {
    margin-right: $space-small !important;
  }
  .push-right-smedium {
    margin-right: $space-smedium !important;
  }
  .push-right-medium {
    margin-right: $space-medium !important;
  }
  .push-right-large {
    margin-right: $space-large !important;
  }
  .push-left-small {
    margin-left: $space-small !important;
  }
  .push-left-smedium {
    margin-left: $space-smedium !important;
  }
  .push-left-medium {
    margin-left: $space-medium !important;
  }
  .push-left-large {
    margin-left: $space-large !important;
  }
  .push-top-small {
    margin-top: $space-small !important;
  }
  .push-top-smedium {
    margin-top: $space-smedium !important;
  }
  .push-top-medium {
    margin-top: $space-medium !important;
  }
  .push-top-large {
    margin-top: $space-large !important;
  }
  .push-top-xlarge {
    margin-top: $space-xlarge !important;
  }
  .push-bottom-xlarge {
    margin-bottom: $space-xlarge !important;
  }
  .push-top-xxlarge {
    margin-top: $space-xxlarge !important;
  }
  .pad {
    padding: 25px;
  }
  .pad-bottom-small {
    padding-bottom: $space-small !important;
  }
  .pad-bottom-smedium {
    padding-bottom: $space-smedium !important;
  }
  .pad-bottom-medium {
    padding-bottom: $space-medium !important;
  }
  .pad-bottom-large {
    padding-bottom: $space-large !important;
  }
  .pad-top-xlarge {
    padding-top: $space-xlarge !important;
  }
  .pad-bottom-xlarge {
    padding-bottom: $space-xlarge !important;
  }
  .pad-bottom-xxlarge {
    padding-bottom: $space-xxlarge !important;
  }
  .pad-left-small {
    padding-left: $space-small !important;
  }
  .pad-left-smedium {
    padding-left: $space-smedium !important;
  }
  .pad-left-medium {
    padding-left: $space-medium !important;
  }
  .pad-left-large {
    padding-left: $space-large !important;
  }
  
  .pad-right-small {
    padding-right: $space-small !important;
  }
  .pad-right-smedium {
    padding-right: $space-smedium !important;
  }
  .pad-right-medium {
    padding-right: $space-medium !important;
  }
  .pad-right-large {
    padding-right: $space-large !important;
  }
  .pad-right-xxlarge {
    padding-right: $space-xxlarge !important;
  }
  .pad-top-small {
    padding-top: $space-small !important;
  }
  .pad-top-smedium {
    padding-top: $space-smedium !important;
  }
  .pad-top-medium {
    padding-top: $space-medium !important;
  }
  .pad-top-large {
    padding-top: $space-large !important;
  }
  .pad-top-large {
    padding-top: $space-large !important;
  }
  .pad-top-xxlarge {
    padding-top: $space-xxlarge !important;
  }
  .flush-top {
    margin-top: 0;
    padding-top: 0;
  }
  .flush-left {
    margin-left: 0;
    padding-left: 0;
  }
  .no-padding {
    padding: 0 !important;
  }
  .flex {
    display: flex;
    flex-direction: row;
  }
  .capitalize {
    text-transform: uppercase;
  }
  .divider {
    width: 100%;
    height: 150px;
  }
  .no-pad {
    padding: 0;
    margin: 0;
  }
  