@mixin breakpoint($point) {
    @if $point == xs {
      @media all and (max-width: 540px) { @content ; }
    }
    @else if $point == sm {
      @media all and (max-width: 768px) { @content ; }
    }
    @else if $point == md {
      @media all and (min-width: 992px) { @content ; }
    }
    @else if $point == lg {
      @media all and (min-width: 1200px) { @content ; }
    }
    @else if $point == xlg {
      @media all and (min-width: 1800px) { @content ; }
    }
    @else if $point == ipad-portrait {
      @media all and (max-width: 768px) { @content ; }
    }
  }
  
  @mixin ease-in {
    transition: all 0.2s ease;
  }
  
  @mixin ease-in-out {
    transition: all .4s ease-in-out;
  }
  
  @mixin fade-in {
    animation: fadein 2s;
  }
  
  @mixin fade-in-one {
    animation: fadein 0.5s;
  }

  @mixin flicker {
    animation: flickerAnimation 5s infinite;
  }
  
  @mixin pulse {
    animation: pulsate 1s ease-in-out infinite;
  }

  @mixin walk {
    -webkit-animation: backandforth 10s ease 0s;
    -webkit-animation-iteration-count:infinite;
    animation: backandforth 10s ease 0s;
    animation-iteration-count:infinite;
  }

  @mixin pop-in($time) {
    @if $time == 2 {
      animation: popin 2s;
    } @else if $time == 3 {
      animation: popin 3s;
    } @else if $time == 4 {
      animation: popin 4s;
    }
  }
  
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.99);
      -webkit-transform: scale(0.99); 
      // transform: scaleX(-1);
    }
    100% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
      // transform: scaleX(1);
    }
}
@-webkit-keyframes backandforth {0%{left:-200px;} 50%{left:18%; } 100%{left:0;}}

@keyframes backandforth {0%{left:-200px;} 50%{left:18%; } 100%{left:0;}}

@keyframes flickerAnimation {
  0%   { opacity:0.1; }
  50%  { opacity:0.3; }
  100% { opacity:0.1; }
}
