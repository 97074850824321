.logo {
  @include breakpoint(xs) {
    width: 40%;
  }
}
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("/imgs/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.stars {
  background-image: url("/imgs/stars.svg");
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  @include flicker;
  @include breakpoint(xs) {
    min-height: 50vh;
    min-width: 50vw;
  }
}

.messageContainer, .messageThankContainer{
  .jubee {
    position:relative;
    width: 30%;
    margin-left: -300px;
    @include pulse;
    // @include walk;
    @include breakpoint(xs) {
      margin-left: -100px;
    }
  }
  .inner {
    background: linear-gradient(180deg, #FDCC00 0%, #E18279 100%);
    padding: 24px;
    margin-top: -24px;
    .message {
      background-color: #272A33;
      border-radius: 8px;
      padding: 16px;
      min-height: 100px;
      & h1 {
        font-size: 0.85em;
        line-height: 1.7em;
        font-family: 'Press Start 2P';
        color: white;
        @include breakpoint(xs) {
          font-size: 0.7em;
          line-height: 1.7em;
        }
      }
    }
  }
}

.groupContainer {
  background-color: white;
  padding: 24px;
  max-height: 450px;
  overflow-y: scroll;
  @include breakpoint(xs) {
    max-height: 600px;
    padding: 16px;
  }
}
.mobile-Container {
  @include breakpoint(xs) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}