$hp-pink: #FF0099;
$hp-blue: #346AD6;

$grey: #3D3D3D;
$p-grey: #474747;
$light-grey: #F7FAFC;


.grey {
  color: #718096 !important;
}
.red {
  color: red;
}
.green {
  color: green;
}
// GLOBAL
.inline-block {
  display: inline-block
}
.underline  {
  text-decoration: underline;
}
.hp-pink {
  background-color: $hp-pink;
}
.hp-pink-c {
  color: $hp-pink;
}
.hp-blue {
  background-color: $hp-blue;
}
.hp-blue-c {
  color: $hp-blue;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.hp-white {
  background-color: white;
}
// TEXT SIZES
.hp-t1 {
  font-size:1.8em; //19px
}
.hp-t2 {
  font-size:2em; //32px
  font-weight: 800;
  line-height: 1.5em;
}
.hp-t3 {
  font-size:2.6em; //42px
}
.hp-t4 {
  font-size:4.6em; //75px
}
.hp-t5 {
  font-size:8.2em; // 130px
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.hp-t6 {
  font-size:5em; // 130px
  font-weight: 800;
  text-transform: uppercase;
  color: $hp-pink;
  text-align: right;
  padding-top: 25px;
}
.add-stroke {
  stroke: black;
  stroke-width: 20;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  color: white;
  -webkit-text-stroke: 2.5px black;
}


// BUTTONS
$btn-small-size: .8em 1em;
$btn-large-size: 1.5em 3em;

// PADDING
$space-xsmall:  5px;
$space-small:   10px;
$space-smedium: 20px;
$space-medium:  30px;
$space-large:   40px;
$space-xlarge:  50px;
$space-xxlarge: 100px;

// ALL CAPS
.caps {
  text-transform: uppercase;
}

// CENTERED
.centered {
  text-align: center;
}
